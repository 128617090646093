
const category = {
  user:{
    label:"Users",
    // icon:<CompareIcon/>
  },
  food:{
    label:"Foods",
    // icon:<CompareIcon/>
  },
  shop:{
    label:"Shop",
    // icon:<CompareIcon/>
  },
}
export default category;
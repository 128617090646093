import Service from "./Service";
import { fetchUtils } from "react-admin";

class CrudService extends Service {
    getList(params) {
        return this.api({ params })
    }
    getOne(params) {
        return this.api({ url: `/${params.id}` })
    }
    getMany(params) {
        return this.api({ params: { filter: { "ids": params.ids } } })
    }
    getManyReference(params) {
        return this.api({
            params: {
                pagination: params.pagination,
                sort: params.sort,
                filter: { ...params.filter, [params.target]: params.id }
            }
        })
    }
    async update(params) {
        if (params.data.deliveryProofPicture) {
            if (params.previousData.deliveryProofPicture !== params.data.deliveryProofPicture) {
                console.log("update params", params)
                let result = await this.upload(params)
                console.log("update res", result.data)
                try {
                    const signedUrlData = {
                        url: result.data.url,
                        fields: result.data.fields
                    };

                    // Prepare the form data
                    const formData = new FormData();
                    Object.entries(signedUrlData.fields).forEach(([key, value]) => {
                        formData.append(key, value);
                    });
                    formData.append('file', params.data.deliveryProofPicture.rawFile);
                    // Upload the file using the signed URL and form data
                    await fetchUtils.fetchJson(signedUrlData.url, {
                        method: 'POST',
                        body: formData,
                        mode: 'no-cors'
                    });

                    // if (response.ok) {
                    //     console.log("response", response)
                    // } else {
                    //     console.error('Error uploading file:', response.statusText);

                    // }
                } catch (error) {
                    // console.error('Error uploading file:', error);
                }
                // const addr = `${result.data.url}${result.data.fields['key']}`
                const addr = `${result.data.fields['key']}`
                console.log("addr", addr)
                return this.api({ url: `/${params.id}`, method: 'PUT', data: { ...params.data, deliveryProofPicture: addr } });
                // .then(({ json }) => ({ data: json }));
            }
        } else {
            return this.api({ url: `/${params.id}`, method: 'PUT', data: params.data });
        }
    }
    create(params) {
        return this.api({ method: 'POST', data: params.data })
    }
    delete(params) {
        return this.api({ url: `/${params.id}`, method: 'DELETE', data: params })
    }
    updateMany(params) {
        return this.api({ url: `/bulk`, method: 'PUT', data: params })
    }
    async upload(params) {
        console.log("upload")
        let result = await this.api({ url: `/upload`, method: 'POST', data: params.data })
        console.log("uploaded result", result)
        return result
    }
    // uploadImageToGCS(file, fileName, bucketName) {
    //     const { Storage } = require('@google-cloud/storage');
    //     const storage = new Storage();
    //     const bucket = storage.bucket(bucketName); // Replace with your bucket name
    //     const fileBlob = bucket.file(fileName);

    //     const stream = fileBlob.createWriteStream({
    //         resumable: false
    //     });

    //     return new Promise((resolve, reject) => {
    //         stream.on('finish', () => {
    //             resolve(fileBlob.publicUrl());
    //         });

    //         stream.on('error', (error) => {
    //             reject(error);
    //         });

    //         stream.end(file);
    //     });
    // }
}

export default CrudService;
import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    TextInput,
    SimpleForm,
    NumberField,
    ReferenceField,
    BooleanField,
    ReferenceInput,
    BooleanInput,
    DateInput,
    DateField,
    required,
    useRecordContext,
    AutocompleteInput,
} from "react-admin";
import { Pagination } from "../../components/Pagination";

const URLField = (props) => {
    const record = useRecordContext(props);
    return record ?
        <span style={{ "cursor": "pointer" }} onClick={() => { navigator.clipboard.writeText("https://colaskitchen.com/?promo=" + window.btoa(record.code)) }}>
            {"https://colaskitchen.com/?promo="}{window.btoa(record.code)}
        </span> : null;
}
URLField.defaultProps = { label: 'URL' };

export const PromoCodeList = (props) => (
    <List pagination={<Pagination/>} {...props} sort={{ field: 'isActive', order: 'DESC' }} >
        <Datagrid rowClick="show">
            <TextField source="code" />
            <ReferenceField source="couponId" reference="coupon">
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="isActive" />
            <TextField source="discount" />
            <TextField source="percentOff" />
            <TextField source="amountOff" />
            <URLField />
        </Datagrid>
    </List>
);

export const PromoCodeEdit = (props) => (
    <Edit {...props} redirect="show">
        <SimpleForm>
            <BooleanInput source="isActive" />
        </SimpleForm>
    </Edit>
);

export const PromoCodeShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="code" />
            <NumberField source="discount" options={{ style: 'percent' }} />
            <NumberField source="percentOff" options={{ style: 'percent' }} />
            <NumberField source="amountOff" options={{ style: 'currency', currency: 'CAD' }} />
            <ReferenceField source="couponId" reference="coupon">
                <TextField source="name" />
            </ReferenceField>
            <BooleanField source="isActive" />
            <BooleanField source="onlyFirstTime" />
            <DateField source="expiresAt" />
            <TextField source="stripeCouponId" />
            <TextField source="stripePromoCodeId" />
            <TextField source="id" />
            <URLField />
        </SimpleShowLayout>
    </Show>
);

export const PromoCodeCreate = (props) => (
    <Create {...props} redirect="show">
        <SimpleForm>
            <TextInput source="code" validate={required()} />
            <ReferenceInput source="couponId" reference="coupon">
                <AutocompleteInput validate={required()}/>
            </ReferenceInput>
            <BooleanInput source="isActive" defaultValue={true} />
            <BooleanInput source="onlyFirstTime" defaultValue={false} />
            <DateInput source="expiresAt" />
        </SimpleForm>
    </Create>
);
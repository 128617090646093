import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    SimpleForm,
    NumberInput,
    required,
    DateField,
} from "react-admin";
import { Pagination } from "../../components/Pagination";

export const CalorieRangeList = (props) => (
    <List pagination={<Pagination/>} {...props} sort={{ field: 'createdAt', order: 'ASC' }} >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="min" />
            <TextField source="max" />
        </Datagrid>
    </List>
);

export const CalorieRangeEdit = (props) => (
    <Edit {...props} redirect="show">
        <SimpleForm>
            <NumberInput source="min" validate={required()} />
            <NumberInput source="max" validate={required()} />
        </SimpleForm>
    </Edit>
);

export const CalorieRangeShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="min" />
            <TextField source="max" />
            <TextField source="id" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);

export const CalorieRangeCreate = (props) => (
    <Create {...props} redirect="list">
        <SimpleForm>
            <NumberInput source="min" validate={required()} />
            <NumberInput source="max" validate={required()} />
        </SimpleForm>
    </Create>
);
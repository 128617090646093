import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    TextInput,
    SimpleForm,
    NumberInput,
    SelectInput,
    FormDataConsumer,
    required,
    ReferenceManyField,
    BooleanField,
    useRecordContext,
} from "react-admin";
import { Pagination } from "../../components/Pagination";

export const CouponList = (props) => (
    <List pagination={<Pagination/>} {...props} sort={{ field: 'updatedAt', order: 'DESC' }} >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="percentOff" />
            <TextField source="amountOff" />
            <TextField source="duration" />
        </Datagrid>
    </List>
);

export const CouponEdit = (props) => (
    <Edit {...props} redirect="show">
        <SimpleForm>
            <TextInput source="name" validate={required()} />
        </SimpleForm>
    </Edit>
);


export const CouponShow = () => (
    <Show>
        <CouponShowLayout />
    </Show>
);


const CouponShowLayout = () => {
    const record = useRecordContext();
    if (!record) return null;
    return (
        <Show >
            <SimpleShowLayout>
                <TextField source="name" />
                <TextField source="type" />
                {record.percentOff && <TextField source="percentOff" label="Percent Off" />}
                {record.amountOff && <TextField source="amountOff" label="Amount Off" />}
                {record.amountOff && <TextField source="currency" label="Currency" />}
                <TextField source="duration" />
                <TextField source="durationInMonths" />
                <ReferenceManyField label="Promo Codes" reference="promoCode" target="coupon">
                    <Datagrid bulkActionButtons={false}>
                        <TextField source="code" />
                        <BooleanField source="isActive" />
                    </Datagrid>
                </ReferenceManyField>
                <TextField source="stripeCouponId" />
                <TextField source="id" />
            </SimpleShowLayout>
        </Show>
    );
};

export const CouponCreate = (props) => (
    <Create {...props} redirect="show">
        <SimpleForm>
            <TextInput source="name" validate={required()} />
            <SelectInput source="type" validate={required()} choices={[
                { id: 'percentOff', name: 'Percent' },
                { id: 'amountOff', name: 'Amount' },
            ]} />
            <FormDataConsumer>
                {({ formData, ...rest }) => {
                    if (formData.type === "percentOff") {
                        return <NumberInput source="percentOff" min={1} validate={required()} {...rest} />
                    } else {
                        return (
                            <>
                                <NumberInput source="amountOff" min={1} validate={required()} />
                                <SelectInput source="currency" validate={required()} choices={[
                                    { id: 'CAD', name: 'CAD' },
                                ]} />
                            </>
                        )
                    }
                }}
            </FormDataConsumer>
            {/* <ConditionalDiscountInput /> */}
            <SelectInput source="duration" validate={required()} choices={[
                { id: 'once', name: 'Once' },
                { id: 'repeating', name: 'Repeating' },
                { id: 'forever', name: 'Forever' },
            ]} />
            <FormDataConsumer>
                {({ formData, ...rest }) => (formData.duration === "repeating") &&
                    <NumberInput source="durationInMonths" validate={required()} min={1} {...rest} />
                }
            </FormDataConsumer>
        </SimpleForm>
    </Create>
);
import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    TextInput,
    SimpleForm,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    DateField,
    BooleanField,
    NumberField,
    TopToolbar,
    EditButton,
    Confirm,
    useRecordContext,
    useDelete,
    useRedirect,
    BooleanInput,
    NumberInput,

    // ReferenceManyField,
} from "react-admin";
import Button from '@mui/material/Button'
import { useState } from 'react';
import { Pagination } from "../../components/Pagination";
// import Alert from '@mui/material/Alert';
// import Snackbar from '@mui/material/Snackbar';

const filters = [
    <ReferenceInput source="owner" reference="user" alwaysOn>
        <AutocompleteInput label="Filter by Owner" filterToQuery={searchText => ({ email: searchText })} optionText={"email"} style={{ 'width': '350px' }} />
    </ReferenceInput>,
    <BooleanInput source="isPregnant" defaultValue={false} />,
    <BooleanInput source="isNursing" defaultValue={false} />,
    <NumberInput source="age_gte" label="Younger than" defaultValue={108}/>,
    <NumberInput source="age_lte" label="Older than" defaultValue={12} />,
    <BooleanInput source="puppy" label="Puppy" defaultValue={false} />,
];

const DogShowActions = () => {
    const redirect = useRedirect();
    const record = useRecordContext();
    const [open, setOpen] = useState(false);
    const [method, setMethod] = useState("cancel");

    const [remove, { isLoading, error }] = useDelete(
        'subscription',
        { id: record && record.id, meta: { method: method } },
        // { onSuccess: () => { handleSuccessfulCancel() }, onError: (error) => { handleErrorCancel(error) } }
    );

    const handleCancelClick = () => {
        setOpen(true);
        setMethod("cancel")
    }
    const handlePauselClick = () => {
        setOpen(true);
        setMethod("pause")
    }
    // const handleSuccessfulCancel = () => {
    //     console.log("success")
    //     setTimeout(function () { showSuccess(); }, 500);
    // }
    // const handleErrorCancel = (error) => {
    //     console.log("Error: ", error)
    //     setTimeout(function () { showError() }, 500);
    // }
    const showSuccess = () => {
        setOpen(false)
        redirect('/dog')
    }
    const showError = () => {
        setOpen(false)
    }
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        // console.log("Cancel Subscription button cliked!")
        remove()
            .then((res) => {
                console.log("res: ", res)
            }).catch((res) => {
                console.log("error: ", res)
            })
        if (error) {
            showError()
        } else {
            showSuccess()
        }

    };
    // const errorAlert = () => (
    //     <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
    //         <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
    //             This is a success message!
    //         </Alert>
    //     </Snackbar>
    // )
    return (
        <TopToolbar>
            <EditButton style={{ padding: "16px", maxHeight: "16px" }} />
            {/* Add your custom actions */}
            {record && (record.status === 'active' || record.status === 'trialing') ? <Button color="error" variant="outlined" style={{ padding: "16px", maxHeight: "16px" }} onClick={handlePauselClick}>Pause Subscription</Button> : null}
            {record && (record.status === 'active' || record.status === 'paused' || record.status === 'trialing') ? <Button color="error" variant="outlined" style={{ padding: "16px", maxHeight: "16px" }} onClick={handleCancelClick}>Cancel Subscription</Button> : null}
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`${method === "cancel" ? "Cancel" : "Pause"} subscription of the ${record && record.name}`}
                content={`Are you sure you want to ${method} this subscription?`}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                cancel="dismiss"
            />
        </TopToolbar>
    )
}

export const DogList = (props) => (
    <List pagination={<Pagination/>} {...props} sort={{ field: 'updatedAt', order: 'DESC' }} filters={filters} >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <ReferenceField link="show" label="Owner" source="owner" reference="user">
                <TextField source="name" />
            </ReferenceField>
            {/* <TextField style={{maxWidth: "10vh", color: "red"}} source="note" /> */}
            <TextField source="breed" />
            {/* <TextField source="gender" />
            <TextField source="activityLevel" />
            <TextField source="shape" />
            <TextField source="id"/> */}
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </Datagrid>
    </List>
);

export const DogEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput fullWidth multiline source="note" />
            {/* <ReferenceField link="show" label="Owner" source="owner" reference="user">
                <TextField source="name" />
            </ReferenceField> */}
            {/* <TextField source="breed" />
            <DateField source="age" />
            <TextField source="gender" />
            <TextField source="activityLevel" />
            <TextField source="shape" />
            <NumberField textAlign="left" source="calorie" />
            <BooleanField source="isAllergic" />
            <BooleanField source="isNursing" />
            <BooleanField source="isPregnant" />
            <BooleanField source="isNeutered" /> */}
        </SimpleForm>
    </Edit>
);

export const DogShow = (props) => (
    <Show {...props} actions={<DogShowActions />}>
        <SimpleShowLayout>
            <TextField source="name" />
            <ReferenceField link="show" label="Owner" source="owner" reference="user">
                <TextField source="name" />
            </ReferenceField>
            <TextField source="note" />
            {/* <ReferenceField link="show" label="Subscription" source="subscription" reference="subscription">
                <TextField source="name" />
            </ReferenceField> */}
            {/* <ReferenceManyField label="Recipes" reference="recipes" target="dog">
              <Datagrid>
                <TextField source="_id" />
                <BooleanField source="isActive" />
                </Datagrid>
            </ReferenceManyField> */}
            <TextField source="breed" />
            <DateField source="age" />
            <TextField source="gender" />
            <TextField source="activityLevel" />
            <TextField source="shape" />
            <NumberField textAlign="left" source="calorie" />
            <BooleanField source="isAllergic" />
            <BooleanField source="isNursing" />
            <BooleanField source="isPregnant" />
            <BooleanField source="isNeutered" />
            <TextField source="id" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);

export const DogCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source="name" />
            <ReferenceInput label="Owner" source="owner" reference="user"/>
            <TextInput source="breed" />
            <DateInput source="age" />
            <TextInput source="gender" />
            <TextInput source="activityLevel" />
            <TextInput source="shape" />
            <NumberInput source="calorie" />
            <BooleanInput source="isAllergic" />
            <BooleanInput source="isNursing" />
            <BooleanInput source="isPregnant" />
            <BooleanInput source="isNeutered" /> */}
        </SimpleForm>
    </Create>
);
import {
    Datagrid,
    List,
    Show,
    Edit,
    Create,
    // required,
    TextField,
    SimpleShowLayout,
    TextInput,
    SimpleForm,
    DateField,
    BooleanField,
    ArrayField,
} from "react-admin";
import { Pagination } from "../../components/Pagination";

const filters = [
    <TextInput label="Filter by Email" source="email" alwaysOn  style={{'width': '350px'}}/>,
  ];
  
export const TempUserList = (props) => (
    <List pagination={<Pagination/>} {...props} sort={{ field: 'updatedAt', order: 'DESC' }} filters={filters}  >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="email" />
            <TextField source="id" />
        </Datagrid>
    </List>
);

export const TempUserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            {/* <TextInput source="name" validate={required()} />
            <TextInput source="email" validate={required()} /> */}
        </SimpleForm>
    </Edit>
);

export const TempUserCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source="key" validate={required()} fullWidth multiline />
            <TextInput source="value" validate={required()} /> */}
        </SimpleForm>
    </Create>
);

export const TempUserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="email" />
            <TextField source="state" />
            {/* <ArrayField source="dogNames">
                <SimpleList key="1"
                    primaryText={record => Object.values(record)}
                />
            </ArrayField> */}
            <ArrayField source="dogs">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="name" />
                    <TextField source="breed" />
                    <TextField source="activityLevel" />
                    <TextField source="shape" />
                    <TextField source="gender" />
                    <TextField source="weight" />
                    <TextField source="calorie" />
                    <BooleanField source="isPregnant"/>
                    <BooleanField source="isNursing"/>
                    <BooleanField source="isNeutered"/>
                    <BooleanField source="isAllergic"/>
                </Datagrid>
            </ArrayField>
            <TextField source="dogsCount" />
            <TextField source="referral" />
            <BooleanField source="isRegistrationDone" />
            <BooleanField source="isDone" />
            <TextField source="id" />
            <TextField source="__v" label="version" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);

import AuthService from "../services/AuthService";
import resourceConfig from "./resourceConfig";
const authService = new AuthService();

const dataProvider =  {
    getList: (resourceName, params) => {
        return resourceConfig[resourceName].service.getList(params).then(res => {
            return res;
        })
    },
    getOne: (resourceName, params) => {
        return resourceConfig[resourceName].service.getOne(params).then(res => {
            return res;
        })
    },
    getMany: (resourceName, params) => {
        return resourceConfig[resourceName].service.getMany(params).then(res => {
            return res;
        })
    },
    getManyReference: (resourceName, params) => {
        return resourceConfig[resourceName].service.getManyReference(params).then(res => {
            return res;
        })
    },
    create: (resourceName, params) => {
        return resourceConfig[resourceName].service.create(params).then(res => {
            return res;
        })
    },
    update: (resourceName, params) => {
        return resourceConfig[resourceName].service.update(params).then(res => {
            return res;
        })
    },
    updateMany: (resourceName, params) => {
        return resourceConfig[resourceName].service.updateMany(params).then(res => {
            return res;
        })
    },
    delete: (resourceName, params) => {
        return resourceConfig[resourceName].service.delete(params).then(res => {
            return res;
        })
    },
    deleteMany: (resourceName, params) => {
        return resourceConfig[resourceName].service.deleteMany(params).then(res => {
            return res;
        })
    },
    login:(params) => authService.login(params),
    getLoginCode:(params) => authService.requestCode(params),
    refreshToken:(params) => authService.refreshToken(params)
};
export default dataProvider;

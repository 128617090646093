import {
    OrderCreate,
    OrderEdit,
    OrderList,
    OrderShow,
} from "./Order";
import {
    ShopItemCreate,
    ShopItemEdit,
    ShopItemList,
    ShopItemShow,
} from "./ShopItem";
import {
    StripeCustomerCreate,
    StripeCustomerEdit,
    StripeCustomerList,
    StripeCustomerShow,
} from "./StripeCustomer";
import {
    SubscriptionCreate,
    SubscriptionEdit,
    SubscriptionList,
    SubscriptionShow,
} from "./Subscription";
import {
    PromoCodeCreate,
    PromoCodeEdit,
    PromoCodeList,
    PromoCodeShow,
} from "./PromoCode";
import {
    CouponCreate,
    CouponEdit,
    CouponList,
    CouponShow,
} from "./Coupon";
import {
    CalorieRangeCreate,
    CalorieRangeEdit,
    CalorieRangeList,
    CalorieRangeShow,
} from "./CalorieRange";
import {
    PriceVersionCreate,
    PriceVersionEdit,
    PriceVersionList,
    PriceVersionShow,
} from "./PriceVersion";
import {
    PriceModelCreate,
    PriceModelEdit,
    PriceModelList,
    PriceModelShow,
} from "./PriceModel";

export const orders = {
    list: OrderList,
    edit: OrderEdit,
    show: OrderShow,
    create: OrderCreate,
};
export const shopItems = {
    list: ShopItemList,
    edit: ShopItemEdit,
    show: ShopItemShow,
    create: ShopItemCreate,
};
export const stripeCustomers = {
    list: StripeCustomerList,
    edit: StripeCustomerEdit,
    show: StripeCustomerShow,
    create: StripeCustomerCreate,
};
export const subscriptions = {
    list: SubscriptionList,
    edit: SubscriptionEdit,
    show: SubscriptionShow,
    create: SubscriptionCreate,
};
export const promoCodes = {
    list: PromoCodeList,
    edit: PromoCodeEdit,
    show: PromoCodeShow,
    create: PromoCodeCreate,
};
export const coupons = {
    list: CouponList,
    edit: CouponEdit,
    show: CouponShow,
    create: CouponCreate,
};
export const calorieRanges = {
    list: CalorieRangeList,
    edit: CalorieRangeEdit,
    show: CalorieRangeShow,
    create: CalorieRangeCreate,
};
export const priceVersions = {
    list: PriceVersionList,
    edit: PriceVersionEdit,
    show: PriceVersionShow,
    create: PriceVersionCreate,
};
export const priceModels = {
    list: PriceModelList,
    edit: PriceModelEdit,
    show: PriceModelShow,
    create: PriceModelCreate,
};

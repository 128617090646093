import {
    Datagrid,
    List,
    Show,
    Edit,
    Create,
    // required,
    TextField,
    SimpleShowLayout,
    SimpleForm,
    // SelectInput,
    // AutocompleteInput,
    // ArrayField,
} from "react-admin";
// import { breedSize, dogBreeds } from "../../config/constants";
import { Pagination } from "../../components/Pagination";

// const filters = [
//     <TextInput label="Filter by Email" source="email" alwaysOn style={{ 'width': '350px' }} />,
// ];


export const BreedList = (props) => (
    <List pagination={<Pagination/>} {...props} sort={{ field: 'updatedAt', order: 'DESC' }} >
        <Datagrid rowClick="show">
        <TextField source="name" />
            <TextField source="size" />
            <TextField source="adultAgeInMounth" />
            <TextField source="seniorAgeInMounth" />
            <TextField source="breedInfo.male.weight.avg" label='Average adult male weight'/>
            <TextField source="breedInfo.female.weight.avg" label='Average adult female weight'/>
        </Datagrid>
    </List>
);

export const BreedEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
        </SimpleForm>
    </Edit>
);

export const BreedCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <AutocompleteInput source="name" choices={dogBreeds.map(item => ({ id: item, name: item }))} fullWidth />
            <SelectInput source="size" choices={breedSize.map(item => ({ id: item, name: item }))} /> */}
        </SimpleForm>
    </Create>
);

export const BreedShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="size" />
            <TextField source="adultAgeInMounth" />
            <TextField source="seniorAgeInMounth" />
            <TextField source="breedInfo.male.weight.avg" label='Average adult male weight'/>
            <TextField source="breedInfo.female.weight.avg" label='Average adult female weight'/>
            
        </SimpleShowLayout>
    </Show>
);

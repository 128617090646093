import { SERVER_URL } from "../config/constants";
// import {stringify} from "querystring";
import axios from "axios";
import { HttpError } from 'react-admin';
// import authProvider from "../config/authProvider";

class Service {
    constructor(basePath = "") {
        this.basePath = basePath
    }

    async api({ basePath = this.basePath, url = "", method = 'get', data, headers = {}, params }) {
        try {
            const result_1 = await axios({
                url: basePath + url,
                method,
                params,
                data,
                baseURL: SERVER_URL,
                headers: { ...this.getHeaders(), ...headers },
                paramsSerializer: this.stringifyParams,
                responseType: 'json',
                withCredentials: true,
            });
            return this.handleResponse(result_1);
        } catch (err) {
            return this.handleError(err);
        }
    }
    getHeaders() {
        const headers = { 'Content-Type': 'application/json' };
        headers.Authorization = localStorage.getItem("Authorization");
        headers.apikey = localStorage.getItem("refreshToken")
        // headers.Cookie = document.cookie
        // console.log("headears: ", document.cookie)
        return headers;
    }
    stringifyParams(params) {
        let query = new URLSearchParams({});
        for (let key of Object.keys(params)) {
            // console.log("params: ", key, params[key])
            if (!(['filter', 'sort', 'pagination'].includes(key))) {
                params[key] && query.append(key, params[key].toString())
            }
        }
        // params["filter"] && query.append("filter", Object.keys(params["filter"]).map(key => {
        //     console.log("filter: ", key, params["filter"][key])
        //     return ({key: params["filter"][key]})
        // }))
        params["filter"] && query.append("filter", JSON.stringify(params.filter))
        params["sort"] && query.append("sort", [params.sort.field, params.sort.order].toString())
        params["pagination"] && query.append("pagination", [params.pagination.page, params.pagination.perPage].toString())
        // console.log("query: ", query)
        return query;
        // return JSON.stringify(pa);
    }
    handleResponse({ headers, data }) {
        const result = {};
        if (data.data[0]) {
            result.data = data.data.map(resource => ({ ...resource, id: resource._id }));
        } else {
            result.data = data.data
            result.data.id = data.data._id
        }
        if (data.total) result.total = data.total
        return result;
    }
    handleError(err) {
        // console.log("err: ", err)
        // const status = err.response.status
        // console.log("status ", status, status === 403)
        // if (status === 401 || status === 403) {
        //     authProvider.logout()
        // }
        return Promise.reject(new HttpError(
            // (err.response.data && err.response.data.message) || err.response.data.statusCode,
            err.code,
            err.message,
            err.name,
        ))
    }
}
export default Service;
import {
    Datagrid,
    List,
    Create,
    Edit,
    Show,
    SimpleShowLayout,
    TextField,
    SimpleForm,
    ArrayField,
} from "react-admin";
import { Pagination } from "../../components/Pagination";

export const ShopItemList = (props) => (
    <List pagination={<Pagination/>} {...props} sort={{ field: 'updatedAt', order: 'DESC' }} >
        <Datagrid rowClick="show">
            <TextField source="name" />
            <TextField source="recipeId" />
            <TextField source="mainIngredient" />
        </Datagrid>
    </List>
);

export const ShopItemEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            {/* <TextInput source="name" />
            <TextInput source="apiName" />
            <NumberInput source="__v" label="version" /> */}
        </SimpleForm>
    </Edit>
);

export const ShopItemShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="name" />
            <TextField source="recipeId" />
            <TextField source="mainIngredient" />
            <TextField source="ingredients" />
            <TextField source="nutrition" />
            <TextField source="benefits" />
            <TextField source="fullIngredients" />
            <ArrayField source="highlights">
                <Datagrid bulkActionButtons={false}>
                    <TextField source="imgPath" />
                    <TextField source="content" />
                </Datagrid>
            </ArrayField>
            <TextField source="id" />
            <TextField source="__v" label="version" />
        </SimpleShowLayout>
    </Show>
);

export const ShopItemCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source="name" />
            <TextInput source="apiName" />
            <NumberInput source="__v" label="version" /> */}
        </SimpleForm>
    </Create>
);
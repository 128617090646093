import {
    IngredientCreate,
    IngredientEdit,
    IngredientList,
    IngredientShow,
} from "./Ingredient";
import {
    RecipeCreate,
    RecipeEdit,
    RecipeList,
    RecipeShow,
} from "./Recipe";

export const ingredients = {
    list: IngredientList,
    edit: IngredientEdit,
    show: IngredientShow,
    create: IngredientCreate,
};
export const recipes = {
    list: RecipeList,
    edit: RecipeEdit,
    show: RecipeShow,
    create: RecipeCreate,
};

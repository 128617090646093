import {
    UserCreate,
    UserEdit,
    UserList,
    UserShow,
} from "./User";
import {
    DogCreate,
    DogEdit,
    DogList,
    DogShow,
} from "./Dog";
import {
    TempUserCreate,
    TempUserEdit,
    TempUserList,
    TempUserShow,
} from "./TempUser";
import {
    WeightCreate,
    WeightEdit,
    WeightList,
    WeightShow,
} from "./Weight";
import {
    BreederCreate,
    BreederEdit,
    BreederList,
    BreederShow,
} from "./Breeder";
import {
    BreedCreate,
    BreedEdit,
    BreedList,
    BreedShow,
} from "./Breed";
import {
    GrowthPatternCreate,
    GrowthPatternEdit,
    GrowthPatternList,
    GrowthPatternShow,
} from "./GrowthPattern";


export const dogs = {
    list: DogList,
    edit: DogEdit,
    show: DogShow,
    create: DogCreate,
};
export const users = {
    list: UserList,
    edit: UserEdit,
    show: UserShow,
    create: UserCreate,
};
export const tempUsers = {
    list: TempUserList,
    edit: TempUserEdit,
    show: TempUserShow,
    create: TempUserCreate,
};
export const weights = {
    list: WeightList,
    edit: WeightEdit,
    show: WeightShow,
    create: WeightCreate,
};
export const breeders = {
    list: BreederList,
    edit: BreederEdit,
    show: BreederShow,
    create: BreederCreate,
};
export const breeds = {
    list: BreedList,
    edit: BreedEdit,
    show: BreedShow,
    create: BreedCreate,
};
export const growthPatterns = {
    list: GrowthPatternList,
    edit: GrowthPatternEdit,
    show: GrowthPatternShow,
    create: GrowthPatternCreate,
};
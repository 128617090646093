import { Admin, Resource } from 'react-admin';
import dataProvider from './config/dataProvider';
import authProvider, { hasPermission } from './config/authProvider';
import resourceConfig from "./config/resourceConfig";
import {theme} from "./config/theme";

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
    >
      {permissions => Object.keys(resourceConfig).map(key => {
        const r = resourceConfig[key];
        return (
          <Resource
            key={key}
            name={key}
            recordRepresentation={r.recordRepresentation}
            list={hasPermission(permissions, r.permission?.list) && r.list}
            show={hasPermission(permissions, r.permission?.show) && r.show}
            edit={hasPermission(permissions, r.permission?.edit) && r.edit}
            create={hasPermission(permissions, r.permission?.create) && r.create}
            // icon={r.icon}
            options={{ category: r.category, label: r.label }} />
        )
      })
      }
    </Admin>
  );
}

export default App;

import Service from "./Service";

class AuthService extends Service{
    login({username,password}){
        // console.log(process.env.REACT_APP_SERVER_ENDPOINT)
        // return Promise.resolve()
        return this.api({basePath:"",url:"/login",method:"POST",data:{username,password}})
    }

}
export default AuthService;
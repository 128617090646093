import CrudService from "../services/CrudService";
import { resource, ROLES } from "./constants";
import category from "./category";
import { users, dogs, tempUsers, weights, breeders, breeds, growthPatterns} from "../pages/Users";
import { ingredients, recipes } from "../pages/Foods";
import { coupons, orders, promoCodes, shopItems, stripeCustomers, subscriptions, calorieRanges, priceVersions, priceModels } from "../pages/Shop";

const { SUPER_ADMIN, ORDER_ADMIN } = ROLES;
const resourceConfig = {
    // import SpeakerNotesIcon from "@material-ui/icons/SpeakerNotes";
    //  Users ↓↓
    [resource.users]: {
        service: new CrudService("/user"),
        permission: { list: [SUPER_ADMIN, ORDER_ADMIN], show: [SUPER_ADMIN, ORDER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...users,
        category: category.user,
        label: "Users",
        recordRepresentation: "name",
    },
    [resource.tempUsers]: {
        service: new CrudService("/tempUser"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...tempUsers,
        category: category.user,
        label: "Temp Users",
        recordRepresentation: "name",
    },
    [resource.dogs]: {
        service: new CrudService("/dog"),
        permission: { list: [SUPER_ADMIN, ORDER_ADMIN], show: [SUPER_ADMIN, ORDER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...dogs,
        category: category.user,
        label: "Dogs",
        recordRepresentation: "name",
    },
    [resource.weights]: {
        service: new CrudService("/weight"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...weights,
        category: category.user,
        label: "Weights",
        recordRepresentation: "id",
    },
    //  Foods ↓↓
    [resource.ingredients]: {
        service: new CrudService("/ingredient"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...ingredients,
        category: category.food,
        label: "Ingredients",
        recordRepresentation: "name",
    },
    [resource.recipes]: {
        service: new CrudService("/recipe"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...recipes,
        category: category.food,
        label: "Recipes",
        recordRepresentation: "id",
    },
    //  Shop ↓↓
    [resource.orders]: {
        service: new CrudService("/order"),
        permission: { list: [SUPER_ADMIN, ORDER_ADMIN], show: [SUPER_ADMIN, ORDER_ADMIN], edit: [SUPER_ADMIN, ORDER_ADMIN], create: [SUPER_ADMIN, ORDER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...orders,
        category: category.shop,
        label: "Orders",
        recordRepresentation: "id",
    },
    [resource.shopItems]: {
        service: new CrudService("/shopItem"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...shopItems,
        category: category.shop,
        label: "Shop Items",
        recordRepresentation: "name",
    },
    [resource.stripeCustomers]: {
        service: new CrudService("/stripeCustomer"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...stripeCustomers,
        category: category.shop,
        label: "Stripe Customers",
        recordRepresentation: "name",
    },
    [resource.subscriptions]: {
        service: new CrudService("/subscription"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...subscriptions,
        category: category.shop,
        label: "Subscriptions",
        recordRepresentation: "id",
    },
    [resource.promoCodes]: {
        service: new CrudService("/promoCode"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...promoCodes,
        category: category.shop,
        label: "Promo Codes",
        recordRepresentation: "code",
    },
    [resource.coupons]: {
        service: new CrudService("/coupon"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...coupons,
        category: category.shop,
        label: "Coupons",
        recordRepresentation: "name",
    },
    [resource.calorieRanges]: {
        service: new CrudService("/calorieRange"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...calorieRanges,
        category: category.shop,
        label: "Calorie Ranges",
        recordRepresentation: "name",
    },
    [resource.priceVersions]: {
        service: new CrudService("/priceVersion"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...priceVersions,
        category: category.shop,
        label: "Price Versions",
        recordRepresentation: "versionNumber",
    },
    [resource.priceModels]: {
        service: new CrudService("/priceModel"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...priceModels,
        category: category.shop,
        label: "Price Models",
        recordRepresentation: "dog",
    },
    [resource.breeders]: {
        service: new CrudService("/breeder"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...breeders,
        category: category.user,
        label: "Breeders",
        recordRepresentation: "name",
    },
    [resource.breeds]: {
        service: new CrudService("/breed"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...breeds,
        category: category.user,
        label: "Breeds",
        recordRepresentation: "name",
    },
    [resource.growthPatterns]: {
        service: new CrudService("/growthPattern"),
        permission: { list: [SUPER_ADMIN], show: [SUPER_ADMIN], edit: [SUPER_ADMIN], create: [SUPER_ADMIN] },
        // icon: <SpeakerNotesIcon />,
        ...growthPatterns,
        category: category.user,
        label: "GrowthPatterns",
        recordRepresentation: "name",
    },
}
export default resourceConfig;
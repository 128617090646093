import dataProvider from "./dataProvider";

const authProvider = {
    login: async ({ username, password }) => {
        const res = await dataProvider.login({ username: username, password: password });
        // console.log("login: ", res);
        // console.log("login2: ", { ...res });
        // const { Authorization, role, refreshToken } = res.data.result;
        // const Authorization = "true"
        const role = res.data.role;
        const refreshToken = res.data.apikey;
        // Cookies.set('user', JSON.stringify(res.data.cookie), {expires:3000, path:'/'})
        // console.log(Authorization, refreshToken, role, res.data.cookie, "Login Results: Autorization + refreshToken")
        // localStorage.setItem("Authorization", Authorization);
        localStorage.setItem("Authorization", true);
        // localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("roles", Array.isArray(role) ? role : [role]);
    },
    logout: () => {
        localStorage.removeItem("Authorization");
        // window.location.replace("/login")
        return Promise.resolve();
    },
    checkError: (error) => {
        if (!error) {return}
        const status = error.status;
        // console.log("error", error)
        if (status === 401 || status === 403) {
            authProvider.logout()
            const refreshToken = localStorage.getItem("refreshToken");
            dataProvider.refreshToken(refreshToken)
                .then((res) => {
                    const { Authorization, role, refreshToken } = res.data.result;
                    localStorage.setItem("Authorization", Authorization);
                    localStorage.setItem("refreshToken", refreshToken);
                    localStorage.setItem("roles", role);
                })
                .catch(() => {
                    return Promise.reject();
                });
            if (!refreshToken) {
                return Promise.reject();
            }
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        if(localStorage.getItem("Authorization")) {
            return Promise.resolve()
        }else {
            // window.location.replace("/login")
            return Promise.reject()
        }
    },
    getPermissions: () => {
        const role = localStorage.getItem("roles");
        return role ? Promise.resolve(role) : Promise.reject();
    },
}

export function hasPermission(userRoles, resourcePermissions) {
    if (!resourcePermissions || !userRoles || resourcePermissions?.length === 0)
        return false;
    return resourcePermissions.some(resourcePermission => userRoles?.includes(resourcePermission));
}





export default authProvider;
import {
    Datagrid,
    List,
    Show,
    Edit,
    Create,
    // required,
    TextField,
    SimpleShowLayout,
    // TextInput,
    SimpleForm,
    DateField,
    ReferenceField,
} from "react-admin";
import { Pagination } from "../../components/Pagination";

export const WeightList = (props) => (
    <List pagination={<Pagination/>} {...props} sort={{ field: 'updatedAt', order: 'DESC' }} >
        <Datagrid rowClick="show">
            <TextField source="weight" />
            <ReferenceField link="show" source="dog" reference="dog" />
            <ReferenceField link="show" source="userId" reference="user" />
            <TextField source="id" />
        </Datagrid>
    </List>
);

export const WeightEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            {/* <TextInput source="name" validate={required()} />
            <TextInput source="email" validate={required()} /> */}
        </SimpleForm>
    </Edit>
);

export const WeightCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            {/* <TextInput source="key" validate={required()} fullWidth multiline />
            <TextInput source="value" validate={required()} /> */}
        </SimpleForm>
    </Create>
);

export const WeightShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="weight" />
            <ReferenceField link="show" source="dog" reference="dog" />
            <ReferenceField link="show" source="userId" reference="user" />
            <TextField source="id" />
            <TextField source="__v" label="version" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
        </SimpleShowLayout>
    </Show>
);

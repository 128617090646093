import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SERVER_URL } from "../config/constants";
import axios from "axios";

export default function ReactivationDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [method,] = React.useState(props.meth);
  const nextWeeksCalculator = (date) => {
    let now = new Date();
    if (date) now = date;
    let t = now.getDay();
    let diff = 0;
    diff = (7 + (1 - t)) % 7 || 7;
    let nextMonday;
    if (diff <= 4) {
      nextMonday = new Date(new Date(now.setDate(now.getDate() + diff + 7)).setHours(0, 0, 0));
    } else {
      nextMonday = new Date(new Date(now.setDate(now.getDate() + diff)).setHours(0, 0, 0));
    }
    let tempNextMonday = new Date(nextMonday);
    let result = [tempNextMonday.toLocaleString('default', { year: "numeric", month: "long", day: "numeric" }).toUpperCase()];
    for (let i = 1; i < 28; i++) {
      tempNextMonday = new Date(tempNextMonday.getTime() + 7 * 24 * 60 * 60 * 1000)
      result.push(tempNextMonday.toLocaleString('default', { year: "numeric", month: "long", day: "numeric" }).toUpperCase())
    }
    return result;
  }
  let nextWeeks = nextWeeksCalculator();

  const [until, setUntil] = React.useState(nextWeeks[0]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = (event) => {
    setUntil(event.target.value);
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        {method === "reactivation" ? "Reactivate Subscription" : "Resume Subscription"}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          onSubmit: async (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = { ...Object.fromEntries(formData.entries()), method: method };
            try {
              const result_1 = await axios({
                url: "subscription/" + props.id,
                method: 'put',
                data: formJson,
                baseURL: SERVER_URL,
                headers: { 'Content-Type': 'application/json', 'Authorization': localStorage.getItem("Authorization"), 'apikey': localStorage.getItem("refreshToken") },
                responseType: 'json',
                withCredentials: true,
              });
              console.log("result_1", result_1)
            } catch (err) {
              console.log(" err", err)
            }
            handleClose();
          },
        }}
      >
        <DialogTitle>{method === "reactivation" ? "Reactivate Subscription" : "Resume Subscription"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {`When should be the next box delivery? Please choose from the list below:`}
          </DialogContentText>
          <InputLabel id="select-label">Delivery Date</InputLabel>
          <Select
            labelId="select-label"
            autoFocus
            required
            id="until"
            name="until"
            label="Delivery Date"
            value={until}
            fullWidth
            onChange={handleChange}
          >
            {nextWeeks.map(function (tempNextMonday, i) {
              return <MenuItem key={i} value={tempNextMonday}>{tempNextMonday}</MenuItem>;
            })}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>DISMISS</Button>
          <Button type="submit">{method === "reactivation" ? "Reactivate" : "Resume"}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

import { defaultTheme } from 'react-admin';

export const theme = {
    ...defaultTheme,
    // palette: { mode: 'dark' },
    components: {
        ...defaultTheme.components,
        RaDatagrid: {
            styleOverrides: {
              root: {
                  backgroundColor: "Lavender",
                  border: "1px solid lightgray",
                  "& .RaDatagrid-headerCell": {
                      backgroundColor: "MistyRose",
                  },
              }
           }
        }
    }
};